.card_space{
    padding-top: 5px;
    padding-bottom: 5px;
}



/* .listing{
    margin-top: 250px;
} */
.searchResult {
    display: flex;
    position: relative;
    margin: 0px;
    padding: 20px;
    border-bottom: 1px solid lightgray;
    cursor: pointer;
   
  }

.searchResult:hover {
    /* opacity: 0.9;
    background-color: #7777770c;
    height: 100%; */
}

.searchResultImage > img {
    height: 200px;
    width: 350px;
    border-radius: 10px;
    overflow: hidden;
}

.searchResult__info2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.searchResult__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 20px;
}
.starting{
    display: flex;
    align-items: center;
    margin-right: 4px;
    font-weight: 500;
}
.searchResult__heart {
    position: absolute;
    top: 30px;
    right: 40px;
}
.searchResult__heart2 {
    margin-right: 30px;
    
}
.searchResult__infoTop {
    width: 40vw;
}

.searchResult__infoTop > h5 {
    margin-top: 5px;
    font: 700 17px / 20.24px Muli, Arial, Helvetica, sans-serif;
    margin-bottom: 4px;
}
.searchResult__infoTop2 > h5 {
    margin-top: 5px;
    /* font: 700 17px / 20.24px Muli, Arial, Helvetica, sans-serif; */
    font-size: 15px;
    margin-bottom: 4px;
    margin-right: 30px;
    line-height: 1.4;
}
.searchResult__infoTop > h7 {
    display: block;
    text-size-adjust: 100%;
    font-size: px;
}
.searchResult__infoTop2 > h7 {
        font: 500 13.3333px Roboto;
        color: #565656c9;
    }

.searchResult__infoTop > h8 {

    margin-top: 20px;
    display: block;
    color: rgb(53, 52, 52);
    
}
.searchResult__infoTop2 > h8 {
    margin-top: 20px;
    display: block;
    color: rgb(53, 52, 52);
    
}
.searchResult__infoTop > p {
    margin-top: 10px;
    font-size: 13px;
    color: gray;
    margin-bottom: 0px;
  }
  .searchResult__infoTop2 > p {
    margin-top: 10px;
    font-size: 13px;
    color: gray;
    margin-bottom: 0px;
  }
.searchResult__infoBottom {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
}

.searchResult__infoBottomCall > h7{
  font-weight: 400;
}
.searchResult__infoBottomCall > p{
    font-size: 15px;
    font-weight: 500;
    margin-top: 3px;
    margin-bottom: 0px;
    color: rgb(42, 41, 41);
  }
.searchResult__stars {
    display: flex;
    align-items: center;
    margin: 0px;
    
}
.searchResult__star {
   
    color: #f50057;
    display: flex;
    /* align-items: center;
   */
}
.searchResult__stars > p {
 
    margin-top: 20px;
    display: flex;
    font-size: 12px;
    font-weight: 500;
    align-items: center;
}
.Dollar{
    color: #f50057;
    align-items: center;
   
}



.searchResults__price > h5 {
    font-weight: 600;
    margin-top: 10px;
  }
  .searchResults__call > h6 {
    font-weight: 400;
   
  }
.button_knowmore{
    
    position: absolute;
    align-items: center;
    right: 40px;
    
}

.button_knowmore:hover{
    transform: scale(1.03);
}

.searchResults__price {
    position: absolute;
    bottom: 20px;
    right: 30px;
}

.searchResults__call {
    position: absolute;
    bottom: 20px;
    right: 30px;
}
.top-left_avai{
    position: absolute;
    top: 40px;
    left: 20px;
    background-color: rgb(35 142 53);
    color: white;
    /* padding-left: 10px;
    padding-right: 10px; */
    padding: 3px 10px 3px ;
    border-radius: 0px 50px 50px 0px;
    font-size: 10px;
    
  }
  .top-left_avai2{
    position: absolute;
    top: 20px;
    left: 0px;
    background-color: rgb(35 142 53);
    color: white;
    /* padding-left: 10px;
    padding-right: 10px; */
    padding: 3px 10px 3px ;
    border-radius: 0px 50px 50px 0px;
    font-size: 10px;
    
  }
 
  .top-left_sold{
    position: absolute;
    top: 40px;
    left: 20px;
    background-color: #f50057;
    color: white;
    /* padding-left: 10px;
    padding-right: 10px; */
    padding: 3px 15px 3px 15px ;
    border-radius: 0px 50px 50px 0px;
    font-size: 10px;
  }
  .top-left_sold2{
    position: absolute;
    top: 20px;
    left: 0px;
    background-color: #f50057;
    color: white;
    /* padding-left: 10px;
    padding-right: 10px; */
    padding: 3px 15px 3px 15px ;
    border-radius: 0px 50px 50px 0px;
    font-size: 10px;
    
  }
  .top-left_sold3{
    position: absolute;
    /* display: inline-block; */
    top: 43px;
    left: 90px;
    /* background-color: rgb(0, 81, 255);
    color: white; */
    /* padding-left: 10px;
    padding-right: 10px; */
    padding: 3px 13px 3px ;
    border-radius: 50px;
    font-size: 20px;
    /* transform: rotate(-10deg) */
  }
  .top-left_soldstamp{
    position: absolute;
    /* display: inline-block; */
    top: 87px;
    left: 97px;
    /* background-color: rgb(0, 81, 255); */
    color: #cf0606;
    /* padding-left: 10px;
    padding-right: 10px; */
    padding: 3px 13px 3px ;
    border-radius: 50px;
    font-size: 19px;
    font-weight: bolder;
    transform: rotate(-19deg)
  }

.top-left_coming{
    position: absolute;
    top: 40px;
    left: 20px;
    background-color: rgb(223, 176, 21);
    color: white;
    /* padding-left: 10px;
    padding-right: 10px; */
    padding: 3px 10px 3px ;
    border-radius: 0px 50px 50px 0px;
    font-size: 10px;
    
  }
  .top-left_coming2{
    position: absolute;
    top: 20px;
    left: 0px;
    background-color: rgb(223, 176, 21);
    color: white;
    /* padding-left: 10px;
    padding-right: 10px; */
    padding: 3px 10px 3px ;
    border-radius: 0px 50px 50px 0px;
    font-size: 10px;
    
  }
.top-left_c_Sold{
    position: absolute;
    top: 40px;
    left: 20px;
    background-color: rgb(223, 102, 21);
    color: white;
    /* padding-left: 10px;
    padding-right: 10px; */
    padding: 3px 10px 3px ;
    border-radius: 0px 50px 50px 0px;
    font-size: 10px;
     
}
.top-left_c_Sold2{
    position: absolute;
    top: 20px;
    left: 0px;
    background-color: rgb(223, 102, 21);
    color: white;
    /* padding-left: 10px;
    padding-right: 10px; */
    padding: 3px 10px 3px ;
    border-radius: 0px 50px 50px 0px;
    font-size: 10px;
     
}
.top-left_active{
    position: absolute;
    top: 10px;
    left: 0px;
    background-color: rgb(4, 133, 184);
    color: white;
    /* padding-left: 10px;
    padding-right: 10px; */
    padding: 3px 10px 3px ;
    border-radius: 0px 50px 50px 0px;
    font-size: 10px;
    
  }
  .top-left_pending{
    position: absolute;
    top: 10px;
    left: 0px;
    background-color: rgb(216, 18, 18);
    color: white;
    /* padding-left: 10px;
    padding-right: 10px; */
    padding: 3px 10px 3px ;
    border-radius: 0px 50px 50px 0px;
    font-size: 10px;
    
  }
  .top-left_AdminOnly{
    position: absolute;
    top: 10px;
    left: 0px;
    background-color: rgb(183, 216, 18);
    color: white;
    /* padding-left: 10px;
    padding-right: 10px; */
    padding: 3px 10px 3px ;
    border-radius: 0px 50px 50px 0px;
    font-size: 10px;
    
  }
.top-Right_Rec{
    position: absolute;
    top: 20px;
    right: 0px;
    background: linear-gradient(-45deg, #dde933, #ec4e70, #d55d23, #ffeb01);
    color: white;
    background-size: 400% 400%;
    padding: 3px 10px 3px ;
    border-radius: 50px 0px 0px 50px;
    font-size: 10px;
    animation:  change 5s ease-in-out infinite;
}
.top-Right_Reclist{
    position: absolute;
    top: 10px;
    right: 0px;
    background: linear-gradient(-45deg, #dde933, #ec4e70, #d55d23, #ffeb01);
    color: white;
    background-size: 400% 400%;
    padding: 3px 10px 3px ;
    border-radius: 50px 0px 0px 50px;
    font-size: 10px;
    animation:  change 5s ease-in-out infinite;
}
.top-Right_lsprice{
    position: absolute;
    top: 10px;
    left: 0px;
    background-color: red;
    color: white;
    /* padding-left: 10px;
    padding-right: 10px; */
    padding: 3px 10px 3px ;
    border-radius: 0px 50px 50px 0px;
    font-size: 10px;
}
.top-Right_soldprice{
    position: absolute;
    top: 10px;
    right: 0px;
    background: red ;
    color: white;
    background-size: 400% 400%;
    padding: 3px 10px 3px ;
    border-radius: 50px 0px 0px 50px;
    font-size: 10px;
    animation:  change 5s ease-in-out infinite;
}

.top-Right_pericesqfeet{
    position: absolute;
    top: 15px;
    right: 0px;
    background: linear-gradient(-45deg, #dde933, #ec4e70, #d55d23, #ffeb01);
    color: white;
    background-size: 400% 400%;
    padding: 3px 13px 3px;
    border-radius: 50px;
    font-size: 10px;
    animation:  change 5s ease-in-out infinite;
}
.top-Right_Rec_mob{
    position: absolute;
    top: 15px;
    right: 10px;
    background: linear-gradient(-45deg, #dde933, #ec4e70, #d55d23, #ffeb01);
    color: white;
    background-size: 400% 400%;
    padding: 3px 13px 3px;
    border-radius: 50px;
    font-size: 10px;
    animation:  change 5s ease-in-out infinite;
}

.rubber { 
    position: absolute;
    top: 40px;
    left: 60px;
    /* transform: rotate(12deg); */
	color: red;
	font-size: 10px;
	font-weight: 700;
	/* border: 0.25rem solid #555; */
	display: inline-block;
	padding: 0.25rem 1rem;
	text-transform: uppercase;
	border-radius: 1rem;
	font-family: 'Courier';
	/* mix-blend-mode: multiply; */
    color: red;
	border: 0.5rem solid red;
	
	transform: rotate(-14deg);
  border-radius: 0;
}
@keyframes change {
    0%{
        background-position: 0 50%;
    }
    50%{
        background-position: 100% 50%;
    }
    100%{
        background-position: 0 50%;
    }
}











#price_1{
   
    border-block-end-color: rgb(255, 206, 52);
    border-block-end-style: solid;
    border-block-end-width: 2px;
 
    border-bottom: 1px solid rgb(255, 206, 52);
    font: 11.2px / 13.44px Muli, Arial, Helvetica, sans-serif;
    margin: 0px;
    padding: 0px 0px 6px;
    display: flex;
}/*#P_2*/

#price_1:after {
    text-align: right;
    text-size-adjust: 100%;
    font: 11.2px / 13.44px Muli, Arial, Helvetica, sans-serif;
}/*#P_2:after*/

#price{
   
    border-block-end-color: rgb(255, 110, 52);
    border-block-end-style: solid;
    border-block-end-width: 2px;
 
    border-bottom: 1px solid rgb(255, 110, 52);
    font: 11.2px / 13.44px Muli, Arial, Helvetica, sans-serif;
    margin: 0px;
    padding: 0px 0px 6px;
    display: flex;
}
#priceDate{
   
    border-block-end-color: rgb(82, 255, 52);
    border-block-end-style: solid;
    border-block-end-width: 2px;
 
    border-bottom: 1px solid rgb(82, 255, 52);
    font: 11.2px / 13.44px Muli, Arial, Helvetica, sans-serif;
    margin: 0px;
    padding: 0px 0px 6px;
    display: flex;
}
#price_from {
    
    text-align: right;
    text-size-adjust: 100%;
    transform-origin: 0px 0px;
    font-size: 14px;
    font-weight: 500;
    /* font-size: 30px;
    font: 700 15.2px / 20.24px Muli, Arial, Helvetica, sans-serif; */
}/*#STRONG_3*/




#price_divider{
    
   
    flex: 1 1 auto;
    font: 12px / 16.8px Muli, Arial, Helvetica, sans-serif;
    margin: 0px;
    padding: 6px 0px;
}/*#P_4*/




#P_1 {
    block-size: 14px;
    box-sizing: border-box;
    height: 14px;
    inline-size: 182px;
    margin-block-end: 0px;
    margin-block-start: 4px;
    min-block-size: auto;
    min-height: auto;
    min-inline-size: auto;
    min-width: auto;
    perspective-origin: 91px 7px;
    text-size-adjust: 100%;
    transform-origin: 91px 7px;
    width: 182px;
    font: 600 12px / 14.4px Muli, Arial, Helvetica, sans-serif;
    margin: 4px 0px 0px;
}/*#P_1*/

#P_1:after {
    text-size-adjust: 100%;
    font: 600 12px / 14.4px Muli, Arial, Helvetica, sans-serif;
}/*#P_1:after*/

#P_1:before {
    text-size-adjust: 100%;
    font: 600 12px / 14.4px Muli, Arial, Helvetica, sans-serif;
}/*#P_1:before*/

#SPAN_2 {
    block-size: 14px;
    box-sizing: border-box;
    display: inline-flex;
    height: 14px;
    inline-size: 91.6562px;
    perspective-origin: 45.8281px 7px;
    text-size-adjust: 100%;
    transform-origin: 45.8281px 7px;
    width: 91.6562px;
    font: 600 12px / 14.4px Muli, Arial, Helvetica, sans-serif;
}/*#SPAN_2*/

#SPAN_2:after {
    display: block;
    text-size-adjust: 100%;
    font: 600 12px / 14.4px Muli, Arial, Helvetica, sans-serif;
}/*#SPAN_2:after*/

#SPAN_2:before {
    display: block;
    text-size-adjust: 100%;
    font: 600 12px / 14.4px Muli, Arial, Helvetica, sans-serif;
}/*#SPAN_2:before*/

.menu {  
    font-size: 14px;  
    background-color: #fff;  
    border-radius: 2px;  
    padding: 5px 0 5px 0;  
    width: 150px;  height: auto;  
    margin: 0; /* use absolute positioning  */  
    position: absolute;  
    list-style: none;  
    box-shadow: 0 0 20px 0 #ccc;  
    opacity: 1;  
    transition: opacity 0.5s linear; 
}