
.info_top_desk > h3 {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 25px;
    font-weight: 500;
    line-height: 1;
    color: #4b4b4be0;
    display: flex;
}


.searchResult__stars2_desk {
    display: flex;
    
}
.searchResult__star_desk {
    color: #f50057;
}
.singleprice_from_desk {
    font: 700 16.2px / 20.24px Muli, Arial, Helvetica, sans-serif;
}
.starting_desk {
    display: flex;
    align-items: center;
    margin-right: 4px;
    font-weight: 500;
}

.Back_desk{
        position: absolute;
        left: 16px;
        top:45%;
}

.Pricebody_desk{
    border-block-end-color: rgb(255, 206, 52);
    border-block-end-style: solid;
    border-block-end-width: 2px;
    border-bottom: 1px solid rgb(255, 206, 52);
    font: 13.2px / 13.44px Muli, Arial, Helvetica, sans-serif;
    margin: 0px;
    padding: 0px 0px 6px;
    display: flex;
    float: right;
}
.price_from_desk{
    text-align: right;
    text-size-adjust: 100%;
    transform-origin: 0px 0px;
    font-size: 14px;
    font-weight: 500;
}

.Next_desk{
        position: absolute;
        right: 16px;
        top:45%;
}

.Arrow_desk{
    background: white;
    border-radius: 30px;
    font-size: 30px;
    color: #2979ff;
}

.detail_container_desk > h3 {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 5px;
}
.detail_container_desk > p {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 15px;
    font-weight: 500;
    color: #666;
    margin-top: 0px;
    margin-bottom: 30px;
}