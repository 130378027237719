.search_box{
    margin-top: -250px;
    z-index: 10;
    
    
}
.searhcontainerc {
    
    border: 1px solid #DDDDDD !important;
    border-radius: 32px !important;
    color: #222222 !important;
    display: -webkit-box !important;
    display: -moz-box !important;
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    height: 66px !important;
    position: relative !important;
    width: 80% !important;
    /* width: 90% !important; */
    box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.15), 0px 3px 8px rgba(0, 0, 0, 0.1) !important;
    --big-search-form-background: #FFFFFF !important;
    background-color: #FFFFFF !important;
    flex: 1 1 0% !important;
    justify-content: space-between;
    min-width: 0px !important;
    pointer-events: auto !important;
    align-items: center;
    margin: auto;
    margin-top: 100px;
    height: 80px;
}
.divider{
        -ms-grid-row-align: center !important;
        -ms-flex-item-align: center !important;
        -webkit-align-self: center !important;
        align-self: center !important;
        border-right: 1px solid #DDDDDD !important;
        -webkit-flex: 0 0 0px !important;
        -ms-flex: 0 0 0px !important;
        flex: 0 0 0px !important;
        height: 32px !important;
    }
    .inner_container{
        cursor: pointer !important;
        display: block !important;
        background-clip: padding-box !important;
        border: 1px solid transparent !important;
        border-radius: 32px !important;
        flex: 1 0 0% !important;
        min-width: 0px !important;
        padding: 30px !important;
        z-index: 3 !important;
        justify-content: space-between;
    }
    .inner_text {
        display: block !important;
        border: 0px !important;
        margin: 0px !important;
        padding: 0px !important;
        width: 100% !important;
        background: none !important;
        font-size: 14px !important;
        line-height: 18px !important;
        font-weight: 600 !important;
        color: #222222 !important;
        text-overflow: ellipsis !important;
    }
    .search_lable{
        color: red;
        margin-top: unset;
    }
    .no_property > img{
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
    }
    .search_input{
        width: 100%;
    }
    
 
.MuiSelect-selectMenu{
    /* padding-right: 24px; */
    /* max-width: 130px; */

}
/* .MuiMenu-paper {
    max-height: calc(100% - 96px);
    min-width: 254px;
   
} */
label + .MuiInput-formControl {
    max-width: 150px;
    margin-top: 0px;
}
label + .MuiInput-formControl {
    margin-top: 16px;
}
@media only screen and (max-width: 960px) {
    label + .MuiInput-formControl {
        max-width: 120px;
        margin-top: 0px;
    }}
@media only screen and (max-width: 600px) {
    label + .MuiInput-formControl {
        max-width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 13px;
    }}
    label + .MuiInput-formControl {
        max-width: 150px;
        margin-top: 0px;
    }
    /* @media only screen and (max-width: 960px) {
        label + .MuiInput-formControl {
            margin-top: 16px;
            max-width: 120px;
        }}
    @media only screen and (max-width: 600px) {
        label + .MuiInput-formControl {
            margin-top: 16px;
            max-width: 120px;
            overflow: hidden;
            text-overflow: ellipsis;
        }} */

       
/* mobile filter................................................... */

.contain{
    padding: 0px 50px 0px 50px;
    margin-top: 100px;
    margin-bottom: 20px;
}
@media only screen and (max-width: 960px) {
    .contain{
        padding: 0px 30px 0px 30px;
        
    }}
.innerContain{
    padding: 20px;
}
.search_mob{
    padding-top: 100px;
}
.search_mob2{
    padding-top: 50px;
}

.mob_hfont > h4{
color: rgb(255, 255, 255);
margin: unset;
text-align: center;
}
.chip_space{
    display: flex;
}
.testContain{
    display: flex;
    overflow-x: auto;
}
.testContain::-webkit-scrollbar {
    display: none;
  }
  .dividermob{
    
        border-right: 1px solid #ff810052!important;
       
        flex: 0 0 0px !important;
        margin-top: 10px;
    margin-bottom: 10px;
       
  }
  .dividerpc{
    border-right: 2px solid #ff810052 !important;
       
    flex: 0 0 0px !important;
    margin-top: 10px;
margin-bottom: 10px;
   
  }
.chip_space{
  padding: 0px 5px 0px 5px;}
  .chipMob{
    display: flex;
    overflow-x: auto;
    padding-top: 10px;
  }
  .chipMob::-webkit-scrollbar {
    display: none;
  }
.BAnner_text{
  color: white;
  text-align: -webkit-center;
}
.BAnner_text >h2{
    font-size: 15px;
    margin-top: -50px;
    margin-bottom: -30px;
  }
@media only screen and (max-width: 600px) {
    .BAnner_text{
        margin-top: -100px;
      }
    }

  @media only screen and (max-width: 600px) {
    .BAnner_text > h2{
        color: white;
        font-size: 25px;
      }
    }

    .Banner_icon_desk{
        margin-top: -200px;
    margin-bottom: -100px;
    width: 350px;
    }