#price_2{
    border-block-end-color: rgb(255, 206, 52);
border-block-end-style: solid;
border-block-end-width: 2px;

border-bottom: 1px solid rgb(255, 206, 52);
font: 13.2px / 11.44px Muli, Arial, Helvetica, sans-serif;
margin: 0px;
padding: 0px 0px 6px;
display: flex;}
#price_from2 {
    
    text-align: right;
    text-size-adjust: 100%;
    transform-origin: 0px 0px;
    font-size: 14px;
    font-weight: 500;
    /* font: 400 15.2px / 10.24px Muli, Arial, Helvetica, sans-serif; */
}/*#STRONG_3*/

.priceContainermob{
    margin-top: 5px;
}

.ButtonContain{
    padding: 15px 10px 8px 10px;
    border-top: 1px solid lightgray;
}
.border_top{
    padding: 10px;
}
.mobhouse_title> h2 {
    margin-top: 0px;
    line-height: 1.2;
    margin-bottom: 0px;
    font-size: 17px;
    font-weight: 400;
    color: rgb(41 121 255);
    padding-bottom: 5px;
    margin-right: 5px;
}
.mobhouse_add> h3{
    margin-top: 0px;
    line-height: 1.2;
    margin-bottom: 0px;
    font-size: 13px;
    font-weight: 400;
    color: rgb(49, 49, 49);
}
.mobhouse_hi> h3{
    margin-top: 0px;
    line-height: 1.2;
    margin-bottom: 0px;
    font-size: 13px;
    padding-bottom: 2px;
    font-weight: 400;
    color: rgb(49, 49, 49);
}
.mobhouse_dis> p{
    margin-top: 0px;
    line-height: 1.2;
    margin-bottom: 0px;
    font-size: 13px;
    padding-bottom: 2px;
    font-weight: 400;
    color: rgb(129, 129, 129);
}
.top-left_avai_mob{
    position: absolute;
    top: 15px;
    left: 10px;
    background-color: rgb(35 142 53);
    color: white;
    /* padding-left: 10px;
    padding-right: 10px; */
    padding: 3px 13px 3px ;;
    border-radius: 50px;
    font-size: 10px;
    
  }
  .top-left_sold_mob{
    position: absolute;
    top: 15px;
    left: 10px;
    background-color: #f50057;
    color: white;
    /* padding-left: 10px;
    padding-right: 10px; */
    padding: 3px 13px 3px ;
    border-radius: 50px;
    font-size: 10px;
  }
  .top-left_sold_mob3{
    position: absolute;
    top: 39px;
    left: 93px;
    /* background-color:red;
    color: white; */
    /* padding-left: 10px;
    padding-right: 10px; */
    padding: 3px 13px 3px ;
    /* border-radius: 50px; */
    font-size: 20px;
    
  }
  .top-left_sold_mob2{
    position: absolute;
    top: 29px;
    left: 83px;
    /* background-color:red;
    color: white; */
    /* padding-left: 10px;
    padding-right: 10px; */
    padding: 3px 13px 3px ;
    /* border-radius: 50px; */
    font-size: 20px;
    /* transform: rotate(-25deg); */
  }
  .top-left_soldstamps{
    position: absolute;
    top: 87px;
    left: 94px;
  
    color:#cf0606;
    /* padding-left: 10px;
    padding-right: 10px; */
    padding: 3px 13px 3px ;
    /* border-radius: 50px; */
    font-size: 24px;
    font-weight: bolder;
    transform: rotate(-19deg);
  }
  .top-left_soldstamps2{
    position: absolute;
    top: 86px;
    left: 102px;
  
    color:#cf0606;
    /* padding-left: 10px;
    padding-right: 10px; */
    padding: 3px 13px 3px ;
    /* border-radius: 50px; */
    font-size: 20px;
    font-weight: bolder;
    transform: rotate(-19deg);
  }
 
.top-left_coming_mob{
    position: absolute;
    top: 15px;
    left: 10px;
    background-color: rgb(223, 176, 21);
    color: white;
    /* padding-left: 10px;
    padding-right: 10px; */
    padding: 3px 13px 3px ;
    border-radius: 50px;
    font-size: 10px;
    
  }

.top-left_c_Sold_mob{
    position: absolute;
    top: 15px;
    left: 10px;
    background-color: rgb(223, 102, 21);
    color: white;
    /* padding-left: 10px;
    padding-right: 10px; */
    padding: 3px 13px 3px ;
    border-radius: 50px;
    font-size: 10px;
     
}
.top-left_avai_mob2{
    position: absolute;
    top: 15px;
    left: 0px;
    background-color: rgb(4, 133, 184);
    color: rgb(255, 255, 255);
    /* padding-left: 10px;
    padding-right: 10px; */
    padding: 3px 13px 3px ;;
    border-radius: 50px;
    font-size: 10px;
    
  }
  .top-left_coming_mob2{
    position: absolute;
    top: 15px;
    left: 0px;
    background-color: rgb(216, 18, 18);
    color: white;
    /* padding-left: 10px;
    padding-right: 10px; */
    padding: 3px 13px 3px ;
    border-radius: 50px;
    font-size: 10px;
    
  }
  .top-left_AdminOnlymob{
    position: absolute;
    top: 15px;
    left: 0px;
    background-color: rgb(183, 216, 18);
    color: white;
    /* padding-left: 10px;
    padding-right: 10px; */
    padding: 3px 13px 3px ;
    border-radius: 50px;
    font-size: 10px;
    
  }
  .top-left_ls_price_mob{
    position: absolute;
    top: 15px;
    left: 0px;
    background-color: red;
    color: white;
    /* padding-left: 10px;
    padding-right: 10px; */
    padding: 3px 13px 3px ;
    border-radius: 50px;
    font-size: 10px;
  }
  .top-left_sold_price_mob{
    position: absolute;
    top: 15px;
    right: 0px;
    background-color: red;
    color: white;
    /* padding-left: 10px;
    padding-right: 10px; */
    padding: 3px 13px 3px ;
    border-radius: 50px;
    font-size: 10px;
  }

  #prices{
   
    border-block-end-color: rgb(255, 110, 52);
    border-block-end-style: solid;
    border-block-end-width: 2px;
 
    border-bottom: 1px solid rgb(255, 110, 52);
    font: 13.2px / 11.44px Muli, Arial, Helvetica, sans-serif;
    margin: 0px;
    padding: 0px 0px 6px;
    display: flex;
}
#priceSoldDate{
   
  border-block-end-color: rgb(82, 255, 52);
  border-block-end-style: solid;
  border-block-end-width: 2px;

  border-bottom: 1px solid rgb(82, 255, 52);
  font: 13.2px / 11.44px Muli, Arial, Helvetica, sans-serif;
  margin: 0px;
  padding: 0px 0px 6px;
  display: flex;
}