.BAnner_text_AU{
    color: white;
}
.BAnner_text_AU > h2{
font-family: "Roboto", "Helvetica", "Arial", sans-serif;
font-size:35px;
font-weight: 500;
line-height: 48px;
margin-top: 24px;
margin-bottom: 24px;
text-align: center;
}
/* @media screen and (max-width: 960px) {
    .BAnner_text_AU > h2{
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-size:35px;
        font-weight: 500;
        line-height: 48px;
        margin-top: 24px;
        margin-bottom: 24px;
        }
} */
.header_icon {
    width: 60px;
}
@media screen and (max-width: 600px) {
    .header_icon {
        width: 42px;
        margin-top: 8px;
    }
  }
@media screen and (max-width: 600px) {
    .BAnner_text_AU > h2{
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-size:30px;
        font-weight: 500;
        line-height: unset;
        margin-top: unset;
        margin-bottom: unset;
        }
  }
.BAnner_text_AU > h5{
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 10px;
    font-weight: 500;
    line-height: unset;
    margin-top: unset;
    margin-bottom: unset;
    text-align-last: center;
    }
    @media screen and (max-width: 600px) {
        .BAnner_text_AU > h5{
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-size: 10px;
            font-weight: 500;
            line-height: unset;
            margin-top: unset;
            margin-bottom: unset;
            
            }
      }

    .BAnner_text_Nav > h3 {
        font-size: 64px;
        font-weight: 500;
        color: white;
        text-align: 'center';
    }
    @media screen and (max-width: 960px) {
        .BAnner_text_Nav > h3 {
            font-size: 54px;
            
        }
    }
    @media screen and (max-width: 600px) {
        .BAnner_text_Nav > h3 {
            font-size: 34px;
            margin-bottom: 0px;
            
        }
      }
    .BAnner_text_Nav > h4 {
        color: white;
    }
    @media screen and (max-width: 600px) {
        .BAnner_text_Nav > h4 {
            margin-top: unset;
        }
      }
    .top-Right_Rec_mob_avb{
       
    position: absolute;
    bottom: -3px;
    background: linear-gradient(-45deg, #dde933, #ec4e70, #d55d23, #ffeb01);
    color: white;
    background-size: 400% 400%;
    padding: 3px 6px 3px;
    border-radius: 0px 0px 5px 5px;
    font-size: 10px;
    animation: change 5s ease-in-out infinite;

    }

    .top-Right_Rec_mob_width{
       
        position: absolute;
        bottom: -3px;
        background: linear-gradient(-45deg, #dde933, #ec4e70, #d55d23, #ffeb01);
        color: white;
        background-size: 400% 400%;
        padding: 3px 6px 3px;
        border-radius: 0px 0px 5px 5px;
        font-size: 10px;
        animation: change 5s ease-in-out infinite;
    
        }
    .top-Right_Rec_des_avb{
       
    position: absolute;
    width:128px;
    bottom: -3px;
    background: linear-gradient(-45deg, #dde933, #ec4e70, #d55d23, #ffeb01);
    color: white;
    background-size: 400% 400%;
    padding: 3px 6px 3px;
    border-radius: 0px 0px 5px 5px;
    font-size: 10px;
    animation: change 5s ease-in-out infinite;

    }
    .top-Right_Rec_des_avb_date{
       
    /* position: absolute; */
    width:128px;
    background: #f52d00de;
    color: white;
    
    background-size: 400% 400%;
    padding: 3px 6px 3px;
    border-radius:  5px 5px 0px 0px;
    font-size: 12px;
    text-align: -webkit-center;

    }
    .top-Right_Rec_mob_avb_date{
       
    /* position: absolute; */
    background: #f52d00de;
    color: white;
    background-size: 400% 400%;
    padding: 3px 6px 3px;
    border-radius:  5px ;
    font-size: 12px;
    text-align: -webkit-center;

    }
    @keyframes change {
        0%{
            background-position: 0 50%;
        }
        50%{
            background-position: 100% 50%;
        }
        100%{
            background-position: 0 50%;
        }
    }

    @media screen and (max-width: 600px) {
        .Mainheader{
            padding-bottom: 60px;
        }
      }