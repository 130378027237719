.Schools_KNW{
  display: flex;
}
.Schools_h6AD{
  font-size: 15px;
  font-weight:430 ;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;

}
.Schools_BT{
 
padding-left: 10px;
 display: flex;

}

.SchoolsA_h6{
    font-size: 0.96rem;
    font-weight:400 ;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;

}
.SchoolsA_h7{
    font-size: 0.97rem;
    font-weight:410 ;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;

}
.Single_tabB{
    font-size: 1rem;
    font-weight:400 ;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
}
.Single_tabA{
  font-size: 1.05rem;
  font-weight:450 ;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
}
.tooltip{
    display: none;
}
/* .great_div{
    display: flex;
  justify-content: space-between;
  align-items: center;
} */

  
  /* .SchoolsD_h6{
    font-size: 15px;
    font-weight:430 ;
    align-items: center; */
  
  /* }
  .Schools_Rating{
    background-color: #c4c2c252;
    border-radius: 50%;
    width: 56px;
    height: 56px;
    font-size: 18px;
    text-align: center;
    line-height: 56px;
   
} */