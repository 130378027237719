.container{
   
    border-radius: 30px;
    padding: 20px;
    margin-top: 20px;
}
.call_button{
    margin-top: 100px;
    
}
.phone > h5{
    margin-top: 10px;
    font-size: 17px;
    margin-left: -5px;
}
.body_area > h5 {
    color: #2196f3;
    font-size: 1.40rem; 
    text-align: center;   
}
.icon_call{
    align-content: center;
}

.phonenumber{
    align-content: center;
}
.avtar{
    align-content: center;
}
.call_icon{
    margin-top: 6px;
}