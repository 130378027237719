.mobsearchResult__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 20px;
}
.mobsearchResult__infoTop2 {
}

.mobsearchResult__infoTop2 > h5 {
    margin-top: 0px;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 4px;
    margin-right: 30px;
    line-height: 1.4;
}

.mobsearchResult__infoTop2 > h7 {
        color: #565656c9;
    }

.mobsearchResult__infoTop2 > h8 {
    margin-top: 20px;
    display: block;
    color: rgb(53, 52, 52);
    
}
.mobsearchResult__infoTop2 > p {
    margin-top: 10px;
    font-size: 13px;
    color: gray;
    margin-bottom: 0px;
  }
.CallButton{
  padding: 15px 0px;
}

.Mob_Schools_h6 {
    font-size: 12px;
    line-height: 1rem;
    font-weight: 430;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
}
.Mob_Schools_h7 {
    font-size: 12px;
    font-weight: 400;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
}
.Mob_Schools_Rating2 {
    background-color: #ffcf22d1;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 18px;
    text-align: center;
    line-height: 40px;
}
.Mob_Single_tab {
    font-size: 13px;
    font-weight: 450;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
}
.Mob_SchoolsD_hs {
    font-size: 14px;
    font-weight: 430;
    align-items: center;
    margin-top: 0;
}
.Mob_Grea_School{
    
    object-fit: contain;
    height: 25px;
    margin-top: 8px;
    margin-bottom: 10px;
    align-items: center;
    margin-right: 5px;
    padding-left: 10px;
}
.top-left_avai_mob_D{
    position: absolute;
    top: 135px;
    left: 10px;
    background-color: rgb(35 142 53);
    color: white;
    padding: 3px 13px 3px;
    border-radius: 50px;
    font-size: 10px;
}

.top-left_sold_mob_D{
    position: absolute;
    top: 80px;
    left: 10px;
    background-color: #f50057;
    color: white;
    padding: 3px 13px 3px;
    border-radius: 50px;
    font-size: 10px;
}
.top-left_coming_mob_D{
    position: absolute;
    top: 80px;
    left: 10px;
    background-color: rgb(223, 176, 21);
    color: white;
    padding: 3px 13px 3px;
    border-radius: 50px;
    font-size: 10px;
}
.top-left_c_Sold_mob_D{
    position: absolute;
    top: 80px;
    left: 10px;
    background-color: rgb(223, 102, 21);
    color: white;
    padding: 3px 13px 3px;
    border-radius: 50px;
    font-size: 10px;
}
.top-Right_Rec_mob_D{
    position: absolute;
    top: 135px;
    right: 10px;
    background: linear-gradient(-45deg, #dde933, #ec4e70, #d55d23, #ffeb01);
    color: white;
    background-size: 400% 400%;
    padding: 3px 13px 3px;
    border-radius: 50px;
    font-size: 10px;
    animation:  change 5s ease-in-out infinite;
}
@keyframes change {
    0%{
        background-position: 0 50%;
    }
    50%{
        background-position: 100% 50%;
    }
    100%{
        background-position: 0 50%;
    }
}
.space_around{
    padding: 5px 0px 10px 5px;
}
.FloorPlanGrid4{
    display: flex;
    overflow-x: auto;

}
.home{
    color: black;
    font-size: 1.1rem;
    font-weight: normal;
    font-family: 'Robto';
    font-style: inherit;
}