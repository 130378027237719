
.container_div{
  width: 100%;
  background-color: white;
}

.Container_singlepage{
  /* min-width: 1340px; */
  /* min-width: 1200px; */
  min-width: 1337px;
  max-width: 1400px;
        margin: 0 auto;
        padding: 20px;
        padding-top: 0px;
        /* background: #f0e68c; */
        background-color: white;
}

.single_body{
  background-color: white;
  /* min-width: 1340px; */
 
}

.info_container {
padding-left: 80px !important;
padding-right: 80px !important;
display: flex;


}
info_child{
  display: flex;
  align-items: center;
  justify-content: space-between;
  
}

.info_top > h3 {
 font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 25px;
  font-weight: 500;
  line-height: 1;
  color: #4b4b4b;
  margin-bottom: 16px;
  display: flex;


  
}
.price{
  display: flex;
  align-items: center;
  margin-right: 100px;
  
 
}

.searchResult__price {
 
 

 /* position: absolute;
 min-width: 100px;
 right: 0px;
 margin-right: 150px;
top: 10px;
align-items: center;

  
  margin-top: 80px; */
}

.searchResult__stars2 {
  margin: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  background-color: white;
  z-index: 100;
  width: 100%;
  min-width: 1120px;
}
.searchResult__stars2 >p{
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex: 1;
}
.searchResult__stars2 >p > strong {
  margin-right: 5px;
}
.searchResult__dollar > h5{
  font-weight: 500;
  font-size: 25px;
}

.headerofimage{
  display: flex;
  margin-top: 10px;
 
}

.headerimage1{
  object-fit: contain;
  width: 100%;
  height: auto;
  border-radius: 50px;
  margin-left: 60px;
  margin-right: 60px;
  
}
.imagebox_container{
 
  display: flex;
  flex-wrap: wrap;
  
}
.imagebox_container > img {
  display: flex;
  flex-wrap: wrap;
  padding-right: 5px;
  padding-left: 5px;
  height: 200px;
  max-width: 250px;
  padding-bottom: 0px;
}
.card_image{
  border-radius: 50px;
 
}
.image_thumb{
  margin-left: 80px;
  margin-right: 80px;
  /* margin: 0; */
}
.lable_chip{
  padding-left: 10px;
}
.chip_green {
  background-color: #ce0f0f;
}
.mortgage{
  margin-left: 20px;
}


#DIV_1 {

  padding-block-start: 24px;
  background-color: white;

}#DIV_1


#DIV_3 {
/* this height is 0 ---------------------------------------- */
  width: 1128px;
  /* width: 100%; */
  /* margin temp */
  
  background-color: #8885851e;
  border-radius: 12px;
  height: 350px;
  overflow: auto hidden;
  
}#DIV_3



#DIV_4 {
  /* block-size: 300px; */
  block-size: 100%;
  position: relative;



}/*#DIV_4*/


#DIV_7 {

  /* inline-size: 564px; */
  inline-size: 564px;
  left: 0px;
  top: 0px;

}/*#DIV_7*/


#DIV_19 {

  left: 564px;
  padding-inline-start: 8px;
  position: absolute;
  top: 0px;
  

}/*#DIV_19*/





#IMG_30, #IMG_54 {
/* tis height 150 ------------------------------------------ */
  height: 175px;
  inline-size: 274px;
  left: 0px;
  object-fit: cover;


}/*#IMG_30, #IMG_54*/



#DIV_32, #DIV_56 {

  padding-block-start: 8px;
 
 
}/*#DIV_32, #DIV_56*/


#DIV_33, #DIV_57 {

  position: relative;
  

}/*#DIV_33, #DIV_57*/



#IMG_41, #IMG_65 {
  /* blosc is 142--------------------------- */
  block-size: 180px;
  inline-size: 274px;
  object-fit: cover;
  top: 0px;
}/*#IMG_41, #IMG_65*/

#DIV_43 {
  
  left: 846px;
  padding-inline-start: 8px;

  position: absolute;
 
  top: 0px;
 
  width: 282px;

}/*#DIV_43*/

.carousel .slide {
  background: #8885851e;
  /* background: rgb(255, 255, 255); */
}

.detail_container{
  margin-top: 10px;
  margin-left: 80px;

}
.footer_text > h6{
  text-align: center;
  color: rgb(84, 83, 88);
  font-size: 0.80rem;
  line-height: 1.6;
  /* font-weight:400 ; */
  font-family:'Roboto' ;
  margin-top: 10px;
  
}
.detail_container > h3{
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 5px;
}
.detail_container > p{
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: #666;
  margin-top: 0px;
  margin-bottom: 30px;

}
.email_box{
  margin-right: 90px;
}


#singleprice_1{
  
  border-block-end-color: rgb(255, 206, 52);
  border-block-end-style: solid;
  border-block-end-width: 2px;
  
  border-bottom: 1px solid rgb(255, 206, 52);
  font: 13.2px / 13.44px Muli, Arial, Helvetica, sans-serif;
  margin: 0px;
  padding: 0px 0px 6px;
  display: flex;
  
}/*#P_2*/

#singleprice_1:after {
  
  
    text-size-adjust: 100%;
  font: 11.2px / 13.44px Muli, Arial, Helvetica, sans-serif;
}/*#P_2:after*/



#singleprice_from {
 
 
  
 overflow: hidden; 
  text-size-adjust: 100%;
  transform-origin: 0px 0px;
  font-size: 30px;
  font: 700 16.2px / 20.24px Muli, Arial, Helvetica, sans-serif;
}/*#STRONG_3*/
.Mortcontainer{
   
  border-radius: 30px;
  padding: 20px;
  margin-top: 20px;
}
#pricecontainer{
 
  

  
 
 
}


#singleprice_divider{
  
  flex: 1 1 auto;
  font: 12px / 16.8px Muli, Arial, Helvetica, sans-serif;
  margin: 0px;
  padding: 6px 0px;
}/*#P_4*/










.cards {
    padding: 1rem;
    
  }
  
  h1 {
    text-align: center;
  }
  
  .cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;
  }
  
  .cards__wrapper {
    position: relative;
    margin: 50px 0 45px;
  }
  
  .cards__items {
    margin-bottom: 24px;
  }
  
  .cards__item {
    display: flex;
    flex: 1;
    margin: 0 1rem;
    border-radius: 10px;
  }
  
  .cards__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
  }
  
  .cards__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
  }
  
  .fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
  }
  
  .cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: #1f98f4;
    box-sizing: border-box;
  }
  
  .cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
  }
  
  .cards__item__img:hover {
    transform: scale(1.1);
  }
  
  .cards__item__info {
    padding: 20px 30px 30px;
  }
  
  .cards__item__text {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
  }
  .inner--card{
      padding: 10px;
  }
  
  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .cards__items {
      display: flex;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .cards__item {
      margin-bottom: 2rem;
    }
  }



  #partners {
    height: 105px;
    background-color: #eee;
    white-space: nowrap;
    width: 100%;
    display: table;
  }
  
  .logo-image {
    vertical-align: middle;
    padding: 13px;
    display: table-cell;
  }
  
  .logo-image img {
    max-width: 100%;
  }

  
  .detail__listing-info {
    clear: both;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0;
    padding: 24px 0 0;
    line-height: 1.2;
    list-style: none;
    font-size: .875rem;
    position: relative;
}
.detail__listing-info li:first-child {
  border-left: 0;
  padding-left: 0;
}
.detail__listing-info li {
 
  padding: 2px 12px;
  height: 18px;
  line-height: 18px;

  white-space: nowrap;
  position: relative;
  margin-bottom: 6px;
}
.detail__info-xlrg {
  font-size: 1.25rem;
}
.detail__listing-info li .detail__price-from {
  position: absolute;
  top: -16px;
  font-size: .95rem;
  font-weight: 400;
}
.detail__listing-info li span, .detail__listing-info li.detail__status {
  color: #4b4b4b;
  font-weight: 700;
}
.nhs_PriceTipIcon {
  display: inline-block;
  position: relative;
  bottom: -1px;
}
.nhs_priceTooltip {
  display: none;
  position: absolute;
  top: -40px;
  left: 29px;
  width: 202px;
  padding: 14px;
  background: #0079d2;
  color: #fff!important;
  font-size: 13px;
  font-weight: 400!important;
  text-align: left;
  white-space: normal;
  box-shadow: 0 0 12px 3px rgba(0,0,0,.5);
  z-index: 999999;
}
.detail__listing-info li {
  color: #666;
  padding: 2px 12px;
  height: 18px;
  line-height: 18px;
  border-left: 1px solid #bababa;
  white-space: nowrap;
  position: relative;
  margin-bottom: 6px;
}
.detail__listing-info li.detail__status {
  color: #4b4b4b;
  font-weight: 700;
}
.detail__listing-info li {
  color: #666;
  padding: 2px 12px;
  height: 18px;
  line-height: 18px;
  border-left: 1px solid #bababa;
  white-space: nowrap;
  position: relative;
  margin-bottom: 6px;
}
.detail__listing-info li.detail__modified {
  font-size: .75rem;
  font-weight: 700;
  color: #666;
  text-transform: uppercase;
  margin-left: auto;
  padding-right: 0;
  border: 0;
}
.detail__listing-info li {
  color: #666;
  padding: 2px 12px;
  height: 18px;
  line-height: 18px;
  border-left: 1px solid #bababa;
  white-space: nowrap;
  position: relative;
  margin-bottom: 6px;
}
abbr, abbr[title] {
  border: 0;
  text-decoration: none;
}
.detail__subtitle {
  display: inline-block;
  font-size: .975rem;
  font-weight: 600;
  line-height: 1.2;
  margin-right: 4px;
  margin: 8px 0 0;
}
.detail__title {
  display: inline;
  margin: 0 12px 0 0;
  font-size: 2.3rem;
  font-weight: 400;
  line-height: 1.1;
  color: #2c2c2c;
  max-width: calc(100% - 250px);
}
.first--paper{
  padding-bottom: 10px;
}