
.header_icon_desk{
  width: 160px;
      margin-top: -10px;
    margin-bottom: -10px;
}

.header_icon_mob{
  width: 160px;
  margin-top: -12px;
  margin-bottom: -12px;
}
.header_icon_mob1{
  width: 35px;
  margin-top: -12px;
  margin-bottom: -12px;
  border-radius: 5px;
  margin-right: 10px;

}













/* 


.Mainheader{
}
.header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  background-color: white;
  z-index: 100;
  top: 0;
  height: 60px;
  box-shadow: 0 2px 2px -2px rgba(0,0,0,.5);
}
.header_icon{
   object-fit: contain;
  width: auto;
   height: 50px;
   margin-left: 60px;
}

.header_center{
  display: flex;
  flex: 1;
  align-items: center;
 
}

.header__center > input {
  border: none;
  padding: 10px;
  outline-width: 0;
}
.header__right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 15vw;
  margin-right: 80px;
}
 */
