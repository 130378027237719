.detail_font {
  margin-top: 20px;
  
}
.detail_font > h6{
  font-size: 14px;
  font-weight:400 ;
  font-family:'Roboto' ;
  margin-top: 10px;
  
}
.detail_font > h5{
  font-size: 15px;
    font-weight:430 ;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
  
}

.Schools_h6{
    font-size: 15px;
    font-weight:430 ;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;

}

.Schools_h7{
    font-size: 14px;
    font-weight:400 ;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;

}
.Schools_map {
  font-size: 14px;
  font-weight:400 ;
  align-items: center;
 margin-top: 10px;
 margin-bottom: 10px;
 

}
.Single_tab{
    font-size: 15px;
    font-weight:450 ;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
}
/* .great_div{
    display: flex;
  justify-content: space-between;
  align-items: center;
} */
.great_schoolLogo {
    object-fit: contain;
    height: 35px;
    margin-top: 10px;
    margin-bottom: 0px;
    align-items: center;
    margin-right: 5px;
  
  }
  .SchoolsD_h6{
    font-size: 15px;
    font-weight:430 ;
    align-items: center;
  
  }
  .SchoolsD_hs{
    font-size: 16px;
    font-weight:430 ;
    align-items: center;
  
  }
  .Schools_Rating{
    background-color:#f50000c7;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 18px;
    text-align: center;
    line-height: 40px;
   
}
.Schools_Rating2{
  background-color: #9df33fd1;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  font-size: 18px;
  text-align: center;
  line-height: 56px;
 
}
.FloorPlanGrid_Desk {
  display: flex;
  overflow-x: auto;
  flex-wrap: wrap;
}

.imageee{
  opacity: 5;
  transition: .5s ease;
  backface-visibility: hidden;
}
.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.containerport:hover .imageee {
  opacity: 0.3;
}

.containerport:hover .middle {
  opacity: 1;
}

.textwrap {
  background-color: rgb(40 40 41 / 65%);
  border-radius: 5px;
  color: white;
  font-size: 16px;
  padding: 8px 10px;
}
.home{
color: black;
font-size: 1.1rem;
font-weight: normal;
font-family: 'Robto';
font-style: inherit;
}