.card_facing{
    display: flex;
    margin-right: 10px;
}
.card_price{
    display: flex;
    margin-left: 10px;
}
.cards__wrap{
    display: flex;
}
.card_button{
    margin-top: -10px;

}
.card_text {
   
    /* line-height: 1.3; */
    
}
.card_text > h7 {
    font-size: 0.96rem;
    font-weight:400 ;
    align-items: center;
    line-height: 0.2;
    
}
.card_bt{
    font-size: 0.96rem;
    font-weight:400 ;
    align-items: center;
    color: rgb(99, 98, 98);
    margin-top: 5px;
}
  
.card_divider{
    background-color: rgb(255 206 52);
}
.AvaGrid{
    display: flex;
    overflow-x: auto;
}
.mobav_title > h2 {
    margin-top: 6px;
    line-height: 1.2;
    margin-bottom: 0px;
    font-size: 14px;
    font-weight: 400;
    color: rgb(41 121 255);
    margin-right: 10px;
}
.MOBButtonContain {
    margin-top: -20px;
    /* padding: 15px 10px 8px 10px; */
    border-top: 1px solid lightgray;
}
.AVA_value{
    padding: 15px 18px 10px;
}
.AVA_value > h5 {
    margin-top: 0px;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 4px;
    margin-right: 30px;
    line-height: 1.4;
}
.MOB_av_no{
    padding: 5px 16px 12px;
}
.Banner_icon_avb_desk{
    margin-top: -100px;
    margin-bottom: -100px;
    width: 350px;
}
.top-Availba_desk{
    position: absolute;
  
    background: #ff8d00;
    color: white;
    background-size: 400% 400%;
    padding: 3px 16px 3px;
    border-radius: 10px 0px 50px;
    font-size: 11px;
}
.top-Availba_desk_AV {
    position: absolute;
    background: #366f03;
    color: white;
    background-size: 400% 400%;
    padding: 3px 16px 3px;
    border-radius: 10px 0px 50px;
    font-size: 11px;
}

.top-Availba_deskmob{
    position: absolute;
    top: 0;
    background: #ff8d00;
    color: white;
    background-size: 400% 400%;
    padding: 3px 16px 3px;
    border-radius: 10px 0px 50px;
    font-size: 11px;
}
.top-Availba_desk_AVmob {
    position: absolute;
    top: 0;
    background: #366f03;
    color: white;
    background-size: 400% 400%;
    padding: 3px 16px 3px;
    border-radius: 10px 0px 50px;
    font-size: 11px;
}